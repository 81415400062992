import React from "react"
import { StaticQuery, graphql, Link } from 'gatsby'

import Layout from "../components/layout"

import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"
import Carousel from "../components/carousel"


const gql_query = graphql`{
  artists: craftAPI { entries(type: "artist") {
    ... on CraftAPI_artist_artist_Entry {
      id
      slug
      title
      artistStatus
      images {
        ... on CraftAPI_artworks_Asset {
          id
          img
          small: url(width: 500)
          filename
          url
        }
      }
    }

}}}`

let sortBySurname = (a, b) => {
    var splitA = a.title.split(" ");
    var splitB = b.title.split(" ");
    var lastA = splitA[splitA.length - 1];
    var lastB = splitB[splitB.length - 1];

    if (lastA < lastB) return -1;
    if (lastA > lastB) return 1;
    return 0;
}

const artist_rows = (artists) => {

  let artist_rows = artists
  .sort(sortBySurname)
  .filter(artist => {
      return artist.artistStatus != "independentArtist"}
  )
  .reduce((resultArray, item, index) => { 
    const chunkIndex = Math.floor(index/3)

    if(!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push({
    type:"stacked", 
    link:"/" + item.slug,
    url:item.images?.[0]?.small,
    details:<div><div className="title">{item.title}</div></div>
    })

    return resultArray
    }, [])
  .map((row) => <ImageRow  forceLength={3} images={row} /> )

  return artist_rows
}
const hero = <Carousel  />
const Artists = () => (
        <StaticQuery query={gql_query} render={(data) => {
            
          
  return <Layout hero={undefined} intro={false}>
    <SEO title="Home" />
    <div style={{ }}>
      
  <h1 style={{marginBottom:"60px", fontSize:"36px",marginTop:"120px", textAlign:"center"}}>Artists</h1>
      {artist_rows(data.artists.entries)}


          

    </div>
  </Layout>}} />
)

export default Artists
